import readXlsxFile from 'read-excel-file'
class ExcelReader {
  constructor(binaryData) {
    this.binaryData = binaryData
  }

  async Read() {
    const data = await readXlsxFile(this.binaryData)
    if (!data || !data.length) {
      return []
    }
    const headers = data[0]
    const res = []
    data.forEach((row, index) => {
      if (index === 0) return // skip headers
      res.push(row.reduce((acc, val, index) => {
        acc[headers[index]] = val || ''
        return acc
      }, {}))
    })
    console.log(res)
    return res
  }
}

export default ExcelReader
