<template>
  <div>
    <h1>
      {{$t('editorialBoard')}}
    </h1>
    <secondary-block class="secondary-editorial">
        <v-row
            class="editorials-main-block"
            v-for="(editor, index) in editors"
            :key="index"
        >
          <p>
            <strong>
              {{editor[`name_${locale.toUpperCase()}`]}}
            </strong>
            {{` ${editor[`job_title_${locale.toUpperCase()}`]}`}}
          </p>
        </v-row>
    </secondary-block>
  </div>
</template>

<script>
import ExcelReader from "../utils/ExcelReader";
import config from '../../config.json'
import SecondaryBlock from "@/components/SecondaryBlock";
export default {
  name: "Editorial-temp",
  components: {SecondaryBlock},
  data: () => ({
    editors: []
  }),
  computed: {
    locale () {
      return this._i18n.locale
    },
    url () {
      return config.NUFT_URL
    }
  },
  async mounted() {
    const binData = await fetch(`${this.url}/jsons/editors.xlsx`) // TODO THIS IS HARDCODE FOR TESTING EXCELL, firstly run python3 server.py
    const blob = await binData.blob()
    const excelReader = new ExcelReader(blob)
    this.editors = await excelReader.Read()
  }
}
</script>

<style scoped>
.secondary-editorial {
  padding-top: 3%;
  padding-bottom: 3%;
}

.editorials-main-block {
  margin-left: 4%;
}
</style>
