<template>
  <div>
    <header-main/>
    <main-body>
      <div v-if="getCustomContent">
        <span v-html="getCustomContent"/>
      </div>
      <div v-else>
        <editorial-temp />
      </div>
    </main-body>
  </div>
</template>

<script>
import mainBody from "../components/MainBody";
import HeaderMain from "@/components/HeaderMain";
import EditorialTemp from "@/components/Editorial-temp";
import config from '../../config.json'
export default {
  name: "Editors",
  components: {
    EditorialTemp,
    HeaderMain,
    mainBody,
  },
  data: () => ({
    customContentua: '',
    customContenten: ''
  }),

  computed: {
    getCustomContent () {
      if (this._i18n.locale === 'en') {
        return this.customContenten
      }

      return this.customContentua
    }
  },

  async mounted() {
    for (const lang of ['en', 'ua']) {
      const response = await fetch(`${config.NUFT_URL}/rawPages/editors_${lang}.html`)
      if (response.status !== 200) {
        continue;
      }
      this[`customContent${lang}`] = await response.text()
    }
  }
}
</script>

<style scoped>

</style>
